:root {
  --h0: 24px;
  --h1: 16px;
  --h2: 14px;
  --h3: 13px;
  --h4: 12px;
  --p1: 12px;
  --p2: 11px;
  --p3: 10px;
  --imgLarge: 25px;
  --imgMedium: 20px;
  --imgSmall: 15px;
  --white: #ffffff;
  --grey: #607783;
  --lightGrey: #d9d9d9;
  --darkGrey: rgba(96, 119, 131, 0.5);
  --green: #44aeb0;
  --orange: #e1bb41;
  --lightOrange: rgb(225, 187, 65, 0.5);
  --red: #fa6969;
  --black: #000000;
  --border: 1px solid rgba(96, 119, 131, 0.5);
  --borderSaved: 1px solid var(--orange);
  --borderColor: rgba(96, 119, 131, 0.5);
  --borderRadiusLarge: 8px;
  --borderRadiusMedium: 4px;
  --borderRadiusSmall: 3px;
}
