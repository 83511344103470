.listBtn {
  width: 50%;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.archiveBtn {
  width: 50%;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.middleBtn {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btnActive {
  background-color: var(--orange);
  border: none;
}

.filterBtnActive {
  background-color: var(--green);
  border: none;
}

.btnNotActive {
  border: var(--border);
  background-color: transparent;
}

.img {
  height: 20px;
  margin: 5px;
}
